<template>
  <div>
  <div class="grid crud-demo">
    <div class="col-12">
      <div class="card">
        <h6 class="">Administração de Condições de Pagamento</h6>
        <Toast/>
        <Toolbar class="mb-4">
          <template v-slot:start>
            <div class="my-2">
              <Button label="Novo" icon="pi pi-plus" class="p-button-success mr-2" @click="openNew" :disabled="true"/>

            </div>
          </template>

          <template v-slot:end>
            <FileUpload mode="basic" accept=".pdf" :maxFileSize="1000000" label="Importar" chooseLabel="Importar" class="mr-2 p-button-outlined" :disabled="true"/>
            <Button label="Exportar" icon="pi pi-download" class="p-button-outlined" @click="exportCSV($event)"  :disabled="true"/>
          </template>
        </Toolbar>

        <DataTable ref="dt" :value="condpgtos" dataKey="id" :paginator="true" :rows="10"
                   v-model:filters="filters" filterDisplay="menu" :loading="loadingTable" :filters="filters"
                   paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
                   currentPageReportTemplate="Showing {first} to {last} of {totalRecords} condições"
                   responsiveLayout="scroll"  :scrollable="true" scrollDirection="both"
                   class="p-datatable-condpgtos">
          <template #header>
            <div class="flex justify-content-between flex-column sm:flex-row">
              <Button type="button" icon="pi pi-filter-slash" label="Limpar Filtros" class="p-button-outlined mb-2" @click="clearFilter1()"/>
              <span class="p-input-icon-left mb-2">
                    <i class="pi pi-search" />
                    <InputText v-model="filters['global'].value" placeholder="Pesquisar..." style="width: 100%"/>
                  </span>
            </div>
          </template>

          <Column field="id" header="ID" :sortable="true" filterField="id" :showFilterMatchModes="false" :filterMenuStyle="{'width':'5%'}" :style="{minWidth:'5%'}" >
            <template #body="slotProps">
              <span class="p-column-title">ID</span>
              {{slotProps.data.id}}
            </template>
            <template #filter="{filterModel}">
              <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Pesquisar pela id"/>
            </template>
          </Column>

          <Column field="codexterno" header="Código" :sortable="true" filterField="codexterno" :showFilterMatchModes="false" :filterMenuStyle="{'width':'10%'}" :style="{minWidth:'10%'}" >
            <template #body="slotProps">
              <span class="p-column-title">Código</span>
              {{slotProps.data.codexterno}}
            </template>
            <template #filter="{filterModel}">
              <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Pesquisar pelo código"/>
            </template>
          </Column>

          <Column field="descricao" header="Descrição" :sortable="true"  :style="{minWidth:'25%'}" >
            <template #body="slotProps">
              <span class="p-column-title">Descrição</span>
              {{slotProps.data.descricao}}
            </template>
            <template #filter="{filterModel}">
              <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Pesquisar pela descrição"/>
            </template>
          </Column>

          <Column field="status" header="Status" :sortable="true" :style="{minWidth:'280px'}">
            <template #body="slotProps">
              <span class="p-column-title">Status</span>
              <span :class="'condpgto-badge status-' + (slotProps.data.status ? slotProps.data.status.toLowerCase() : '')">{{ nomeStatus(slotProps.data.status) }}</span>
            </template>
            <template #filter="{filterModel}">
              <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Pesquisar pelo status"/>
            </template>
          </Column>

          <Column header="Criado" dataType="date" style="min-width:10rem">
            <template #body="slotProps">
              {{formatDate(slotProps.data.created_at)}}
            </template>
          </Column>


          <Column field="rating" header="Utilização" :sortable="true" class="text-right" :style="{minWidth:'180px', justifyContent:'flex-end'}">
            <template #body="slotProps">
              <span class="p-column-title">Rating</span>
              <Rating :modelValue="slotProps.data.rating" :readonly="true" :cancel="false" :style="{ justifyContent:'flex-end'}"/>
            </template>
          </Column>

          <Column field="valormin" header="Valor Mínimo" :sortable="true" class="text-right" :style="{minWidth:'140px', justifyContent:'flex-end'}" >
            <template #body="slotProps">
              <span class="p-column-title">Valor Mínimo</span>
              {{formatCurrency(slotProps.data.valormin)}}
            </template>
            <template #filter="{filterModel}">
              <InputNumber v-model="filterModel.value" mode="currency" currency="BRL" locale="pt-BR" class="text-right" placeholder="Pesquisar pelo valor mínimo"/>
            </template>
          </Column>

          <Column field="qtditens" header="Volume Mínimo" :sortable="true" class="text-right" :style="{minWidth:'200px', justifyContent:'flex-end'}" >
            <template #body="slotProps">
              <span class="p-column-title">Volume Mínimo</span>
              {{formatDecimal(slotProps.data.qtditens)}}
            </template>
            <template #filter="{filterModel}">
              <InputNumber v-model="filterModel.value" mode="decimal" locale="pt-BR" :minFractionDigits="2" class="text-right" placeholder="Pesquisar pelo volume mínimo"/>
            </template>
          </Column>


          <Column header="Ações" class="text-right" :style="{width:'180px'}"  frozen alignFrozen="right">
            <template #body="slotProps" >
              <Button icon="pi pi-trash" class="p-button-rounded p-button-warning mr-2" @click="confirmDelete(slotProps.data)" />
              <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" @click="edit(slotProps.data.id)" />
              <Button icon="pi pi-sort-amount-up" class="p-button-rounded p-button-help " @click="visibleTimeline = true" />
            </template>
          </Column>

        </DataTable>




        <Dialog v-model:visible="editDialog"
                :style="{width: '450px'}"
                :header="condpgto.id > 0 ? 'Detalhes da Condição (' + condpgto.id + ')' : 'Nova Condição'"
                :modal="true"
                class="p-fluid">

          <div class="field">
            <label for="codexterno">Código</label>
            <InputText id="codexterno" v-model.trim="condpgto.codexterno" required="true" :class="{'p-invalid': submitted && !condpgto.codexterno}" />
            <small class="p-invalid" v-if="submitted && !condpgto.codexterno">Código é obrigatório.</small>
          </div>

          <div class="field">
            <label for="descricao">Descrição</label>
            <Textarea id="descricao" v-model="condpgto.descricao" required="true" rows="3" cols="20" :class="{'p-invalid': submitted && !condpgto.descricao}" />
            <small class="p-invalid" v-if="submitted && !condpgto.descricao">Descrição é obrigatório.</small>
          </div>

          <div class="field">
            <label for="status">Status</label>

            <Dropdown id="status"
                      v-model="condpgto.status"
                      :options="statuses"
                      optionLabel="name"
                      :class="{'p-invalid': submitted && !condpgto.status}"
                      optionValue="code"
                      placeholder="Selecione o Status">
              <template #value="slotProps">
                <div v-if="slotProps.value && slotProps.value.value">
                  <span :class="'condpgto-badge status-' +slotProps.value.value"> {{ nomeStatus(slotProps.value.value) }}</span>
                </div>
                <div v-else-if="slotProps.value && !slotProps.value.value">
                  <span :class="'condpgto-badge status-' +slotProps.value.toLowerCase()">{{ nomeStatus(slotProps.value) }}</span>
                </div>
                <span v-else>
									{{slotProps.placeholder}}
								</span>
              </template>
            </Dropdown>
            <small class="p-invalid" v-if="submitted && !condpgto.status">Status é obrigatório.</small>

          </div>

          <div class="formgrid grid">
            <div class="field col">
              <label for="valormin">Valor Mínimo</label>
              <InputNumber id="valormin" v-model="condpgto.valormin" mode="decimal" locale="pt-BR" :minFractionDigits="2" />
            </div>
            <div class="field col">
              <label for="qtditens">Volume Mínimo</label>
              <InputNumber id="qtditens" v-model="condpgto.qtditens" mode="decimal" locale="pt-BR" :minFractionDigits="2" />
            </div>
          </div>

          <div class="formgrid grid">
            <div class="field col">
              <label for="hiddenf1"></label>

            </div>

            <div class="field col">
              <label for="desconto">Desconto</label>
              <InputNumber id="desconto" v-model="condpgto.desconto" mode="decimal" locale="pt-BR" :minFractionDigits="2" />
            </div>
          </div>


          <template #footer>
            <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
            <Button label="Salvar" icon="pi pi-check" class="p-button-text" @click="save" />
          </template>
        </Dialog>

        <Dialog v-model:visible="deleteDialog" :style="{width: '450px'}" header="Confirmar Exclusão" :modal="true">
          <div class="flex align-items-start justify-content-start mt-3">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
            <span v-if="condpgto">Confirma a exclusão de <b>{{condpgto.descricao}}</b>?</span>
          </div>

          <template #footer>
            <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="deleteDialog = false"/>
            <Button label="Confirmar" icon="pi pi-check" class="p-button-text" @click="deletar" />
          </template>
        </Dialog>


      </div>
    </div>


  </div>
    <div class="grid p-fluid">
      <div class="col-12 lg:col-6">
        <div class="card">
          <h6>Evolução Valor Mensal</h6>
          <Chart type="bar" :data="barData" :options="barOptions"></Chart>
        </div>
      </div>
      <div class="col-12 lg:col-6">
        <div class="card flex flex-column align-items-center">
          <h6>Status</h6>
          <Chart type="doughnut" :data="pieData" :options="pieOptions" style="position:relative; width: 50%"></Chart>
        </div>
      </div>
    </div>


    <div class="col-12 lg:col-12">
      <Sidebar v-model:visible="visibleTimeline" :baseZIndex="1000" position="right">
        <h3 style="font-weight: normal">Timeline</h3>
<!--        <condpgtos-time-line></condpgtos-time-line>-->
      </Sidebar>
    </div>
  </div>

</template>

<script>
import {FilterMatchMode, FilterOperator} from 'primevue/api';
import CondPgtoService from '@/service/CondPgtoService';


export default {
  components: {

  },
  data() {
    return {
      loadingBtnSave: false,
      visibleTimeline: false,
      loadingTable: true,

      condpgtos: null,
      editDialog: false,
      deleteDialog: false,

      condpgto: {},
      filters: {},
      submitted: false,
      statuses: [
        {name: 'ATIVO', code: 'A'},
        {name: 'INATIVO', code: 'I'},
      ],

      barData: {},
      barOptions: {
        plugins: {
          legend: {
            labels: {
              color: '#A0A7B5'
            }
          }
        },
        scales: {
          x: {
            ticks: {
              color: '#A0A7B5'
            },
            grid: {
              color:  'rgba(160, 167, 181, .3)',
            }
          },
          y: {
            ticks: {
              color: '#A0A7B5'
            },
            grid: {
              color:  'rgba(160, 167, 181, .3)',
            }
          },
        }
      },

      pieData: {},
      pieOptions: {
        plugins: {
          legend: {
            labels: {
              color: '#A0A7B5'
            }
          }
        }
      },


      radarData: {
        labels: ['GD1', 'GD0', 'MD1', 'MD0', 'PQ2', 'PQ1', 'PQ0'],
        datasets: [
          {
            label: 'ES',
            backgroundColor: 'rgba(54, 162, 235,0.2)',
            borderColor: 'rgba(54, 162, 235,1)',
            pointBackgroundColor: 'rgba(54, 162, 235,1)',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgba(54, 162, 235,1)',
            data: [65, 59, 90, 81, 56, 55, 40]
          },
          {
            label: 'MG',
            backgroundColor: 'rgba(255, 99, 132,0.2)',
            borderColor: 'rgba(255, 99, 132,1)',
            pointBackgroundColor: 'rgba(255, 99, 132,1)',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgba(255, 99, 132,1)',
            data: [28, 48, 40, 19, 96, 27, 100]
          }
        ]
      },

      radarOptions: {
        plugins: {
          legend: {
            labels: {
              color: '#A0A7B5'
            }
          }
        },
        scales: {
          r: {
            grid: {
              color: 'rgba(160, 167, 181, .3)'
            }
          }
        }
      },

    }
  },
  condpgtoService: null,
  created() {
    this.condpgtoService = new CondPgtoService();
    this.initFilters();
  },
  mounted() {
    this.getTabela()

  },
  methods: {

    formatDate(value) {
      return value.toLocaleDateString('pt-BR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      });
    },

    nomeStatus(sigla){
      console.log(sigla)
      switch (sigla) {

        case 'A':
          return 'Ativo'

        case 'I':
            return 'Inativo'

        default:
          return 'Não Definido'

      }
    },

    getTabela(){
      this.loadingTable = true
      this.condpgtoService.getCondPgtos().then((data) => {
        console.log('condpgtosList mounted')
        console.log(data)
        this.loadingTable = false
        this.condpgtos = data[0]

        this.condpgtos.forEach((cond) => {
          cond.created_at = new Date(cond.created_at)
        });

        this.barData ={
          labels: data[1],
              datasets: [
            {
              label: 'Elaboração',
              backgroundColor: '#FEEDAF',
              borderColor: '#8A5340',
              data: data[2]
            },
            {
              label: 'Confirmado',
              backgroundColor: '#C8E6C9',
              borderColor: '#256029',
              data: data[3]
            }
          ]
        }

        this.pieData = {
          labels: data[4],
              datasets: [
            {
              data: data[5],
              backgroundColor: [
                'rgb(54, 162, 235)',
                'rgb(255, 99, 132)',
                'rgb(255, 205, 86)',
                'rgb(75, 192, 192)'
              ]
            }]
        }

      })
    },

    formatDecimal(value) {
      if(value)
        return value.toLocaleString('pt-BR', {style: 'decimal', minimumFractionDigits: 2});
      return '';
    },

    formatCurrency(value) {
      if(value)
        return value.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'});
      return '';
    },

    openNew() {
      this.condpgto = {};
      this.submitted = false;
      this.editDialog = true;
    },

    hideDialog() {
      this.editDialog = false;
      this.submitted = false;
    },

    save() {
      this.submitted = true
      if (this.condpgto.codexterno && this.condpgto.descricao){

        this.loadingBtnSave = true
        this.condpgtoService.saveCondPgto(this.condpgto).then(() => {
          console.log('save')
          this.loadingBtnSave = false
          this.$toast.add({severity:'success', summary: 'Registro atualizado', detail:'As informações foram atualizadas com sucesso', life: 3000});
          this.editDialog = false;
          this.condpgto = {};
          this.getTabela()
        });

      }

    },

    edit(id) {
      this.condpgtoService.getCondPgto(id).then((data) => {
        this.clienteData = data[0]
        console.log('getReg')
        console.log(data)
        if (data === 'nao_permitido') {
          this.$router.replace({ path: '/acessorestrito' })
        }else{
          this.condpgto = data[0];
          this.editDialog = true;
        }
      })

    },
    confirmDelete(condpgto) {
      this.condpgto = condpgto;
      this.deleteDialog = true;
    },

    deletar() {

      this.condpgtoService.deleteCondPgto(this.condpgto.id).then(() => {
        this.$toast.add({severity:'success', summary: 'Registro excluído', detail:'As informações foram excluídas com sucesso', life: 3000})
        this.deleteDialog = false
        this.condpgto = {}
        this.getTabela()
      })
    },


    exportCSV() {
      this.$refs.dt.exportCSV();
    },

    initFilters() {
      this.filters = {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
        'id': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
        'codexterno': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
        'descricao': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
        'status': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]},
        'valormin': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
        'qtditens': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},

      }

    },


    clearFilter1() {
      this.initFilters();
    },
  }
}
</script>

<style scoped lang="scss">
@import '../../assets/demo/styles/badges.scss';



</style>
