import api from "@/axios";

export default class CondPgtoService {

    getCondPgtos() {
        // return fetch('demo/data/leads.json').then(res => res.json()).then(d => d.data);
        return api.get('api/sscondpgtoindex' ).then((res) => res.data);
    }

    getCondPgto(id) {
        const params = { id }
        return api.get('api/sscondpgtoget', {params}).then((res) => res.data);
    }

    saveCondPgto(condpgto) {
        return api.post('api/sscondpgtosave', {condpgto} ).then((res) => res.data);
    }

    deleteCondPgto(id) {
        const params = { id }
        return api.get('api/sscondpgtodel', { params }).then((res) => res.data);
    }

}
